#footer{

    border-top: 1px solid rgb(214, 214, 214);
    
    color:white;
    font-family: 'Poppins', sans-serif;
}

.round-bor{
    display: flex;
    justify-items: center;
    align-items: center;
    width:30px;
    height:30px;
    border-radius:50%;
    
}