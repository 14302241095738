.nav-h:hover{

    color:#d902ff ;
    

}

.nav-h{

    transition: 0.5s;

}